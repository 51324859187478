<template>
  <div class="backColorBlue height100" v-if="registeredUser.registered == true">
    <div class="colorBlack1">
      <div class="trans height44">
        <navbar />
      </div>
      <div class="homewelkom height110">
        <HomeWelkom />
      </div>
    </div>
    <router-view />
  </div>
  <div class="registerNewPerson" v-else-if="registeredUser.registered == false">
    <registerNewPerson />
  </div>
  <Loading v-else />
  <Toast />
</template>

<script>
import navbar from "@/components/App/Navsidebar/navbar.vue";
import registerNewPerson from "@/components/App/Dashboard/RegisterNewPerson.vue";
import HomeWelkom from "@/components/App/HomeWelkom.vue";
import Loading from "@/components/App/Loading.vue";
import { getOverzichtData, fetchData } from "@/fetch";

export default {
  el: "#app",
  inject: ["auth"],
  components: {
    navbar,
    HomeWelkom,
    Loading,
    registerNewPerson,
  },
  mounted() {
    this.fetchData();
    this.registeredUser = fetchData;
  },
  methods: {
    async fetchData() {
      getOverzichtData();
    },
  },
  data() {
    return {
      registeredUser: {},
    };
  },
};
</script>
