<template>
  <div class="new-user-form">
    <div class="surface-section">
      <div class="font-medium font-sub-header text-900 mb-3">Je gegevens</div>
      <div class="text-500 font-m mb-5">
        Om Eetjethuis.nl op de juiste manier te kunnen gebruiken, hebben we een
        aantal gegevens van je nodig. Deze gegevens kun je hieronder invoeren.
      </div>
      <ul class="list-none p-0 m-0">
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-500 w-6 md:w-2 font-m">Je voornaam</div>
          <div
            class="text-900 w-full md:w-10 md:flex-order-0 font-m flex-order-1"
          >
            <InputText
              type="text"
              id="firstname"
              style="width: 100%"
              v-model="firstName"
              @click="changebordercolorfname()"
            />
          </div>
          <!-- <div class="w-6 md:w-2 flex justify-content-end">
            <Button
              label="Edit"
              icon="pi pi-pencil"
              class="p-button-text"
            ></Button>
          </div> -->
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap padding-top-30"
        >
          <div class="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
            <InlineMessage severity="error" id="error" style="display: none"
              >Vul alle verplichte velden in!</InlineMessage
            >
          </div>
          <div class="w-6 md:w-2 flex justify-content-end">
            <Button
              label="Opslaan"
              icon="pi pi-save"
              class="p-button-text"
              @click="registerUser()"
            ></Button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import keycloak from "@/main";
import { serverURL } from "@/fetch";

const firstName = ref(null);

function registerUser() {
  if (firstName.value == null || firstName.value == "") {
    document.getElementById("firstname").style.border = "1px solid red";
    document.getElementById("error").style.display = "block";
  } else {
    fetch(`${serverURL}/server/users/command/create-user`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + keycloak.idToken,
      },
      body: JSON.stringify({ name: firstName.value }),
    });
    location.reload();
  }
}

function changebordercolorfname() {
  document.getElementById("firstname").style.border = "1px solid #ced4da";
  document.getElementById("error").style.display = "none";
}
</script>

<script>
export default {
  name: "registerNewPersonForm",
};
</script>
