import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Aanmelden from "../views/Aanmelden.vue";
import AanmeldenFeestdagen from "../views/AanmeldenFeestdagen.vue";
import LocationOverview from "../views/LocationOverview.vue";
import Profiel from "../views/Profiel.vue";
import PageNotFound from "../views/PageNotFound.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Home,
    },
    {
      path: "/Aanmelden",
      name: "Aanmelden",
      component: Aanmelden,
    },
    {
      path: "/Feestdagen",
      name: "Feestdagen",
      component: AanmeldenFeestdagen,
    },
    {
      path: "/LocationOverview",
      name: "LocationOverview",
      component: LocationOverview,
    },
    {
      path: "/Profiel",
      name: "Profiel",
      component: Profiel,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "PageNotFound",
      component: PageNotFound,
    },
  ],
});

export default router;
