<template>
  <div class="Home">
    <div class="classDashboard">
      <HomeDashboard />
    </div>
  </div>
</template>

<script>
import HomeDashboard from "@/components/App/Dashboard/HomeDashboard.vue";
export default {
  name: "Home",
  components: {
    HomeDashboard,
  },
};
</script>
