<template>
  <Toast />
  <div class="Home">
    <div style="margin: 1rem">
      <ChangeProfileForm />
    </div>
  </div>
</template>

<script>
import ChangeProfileForm from "@/components/App/Profiel/ChangeProfileForm.vue";
export default {
  name: "Profiel",
  components: { ChangeProfileForm },
};
</script>
