<template>
  <div id="notfound">
    <div class="notfound">
      <div class="notfound-404">
        <h1>404</h1>
      </div>
      <h2>Pagina niet gevonden! &#128546;</h2>
      <p>Sorry, we konden de gevraagde pagina niet vinden.</p>
      <RouterLink to="/">Terug naar de homepagina</RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>
