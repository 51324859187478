<template>
  <div class="loading">
    <div class="content">
      <div>
        <ProgressSpinner />
      </div>
      De pagina wordt geladen ...
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
.loading {
  height: 100vh;
  width: 100vw;
}

.content {
  text-align: center;
  position: absolute;
  width: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
