<template>
  <span
    class="block font-m mb-3"
    :class="{ dateToday: formattedToday === dates.date }"
  >
    <i class="fa-regular fa-calendar fa-xs"></i>
    {{ weekday[index].day }} - {{ dateTime(dates.date) }}</span
  >
  <div class="text-900 font-m capitalize" v-for="users in dates.registrations">
    <span v-if="users.id == registeredUser.id" style="text-align: center">
      <span style="float: left; clear: left">
        <i class="fa-solid fa-user fa-xs colorBlue"></i>
        {{ users.name }} (jij)
      </span>
      <span
        style="
          float: left;
          clear: left;
          text-align: left;
          user-select: text !important;
        "
        class="text-color-gray not-capitalize"
        v-if="users.memo"
      >
        <i class="fa-regular fa-comment fa-sm"></i> {{ users.memo }}</span
      >
    </span>
    <span v-else>
      <span style="float: left; clear: left">
        <i class="fa-solid fa-user fa-xs"></i> {{ users.name }}
      </span>
      <span
        style="float: left; clear: left"
        class="opmerkingen text-color-gray not-capitalize"
        v-if="users.memo"
      >
        <i class="fa-regular fa-comment fa-sm"></i> {{ users.memo }}</span
      >
    </span>
  </div>
  <div v-if="!dates.registrations[0]" class="text-color-gray font-s">
    Er zijn nog geen aanmeldingen voor deze dag!
  </div>
</template>

<script>
import moment from "moment";
import { fetchData } from "@/fetch";
export default {
  name: "PersonList",
  props: ["dates", "index", "weekday", "formattedToday"],
  mounted() {
    this.registeredUser = fetchData;
  },
  data() {
    return {
      registeredUser: {},
    };
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>
