import { createApp } from "vue";
import App from "./App.vue";
import Keycloak from "keycloak-js";
import router from "./router";
import PrimeVue from "primevue/config";
import "@fortawesome/fontawesome-free/js/all";
import "primevue/resources/themes/lara-light-cyan/theme.css";
// import "primevue/resources/themes/lara-dark-cyan/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/style.css";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import Button from "primevue/button";
import TabMenu from "primevue/tabmenu";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import InputSwitch from "primevue/inputswitch";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import InputText from "primevue/inputtext";
import Skeleton from "primevue/skeleton";
import InputMask from "primevue/inputmask";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileupload";
import TieredMenu from "primevue/tieredmenu";
import InlineMessage from "primevue/inlinemessage";
import Divider from "primevue/divider";
import Textarea from "primevue/textarea";
import Panel from "primevue/panel";
import moment from "moment";
import ProgressSpinner from "primevue/progressspinner";
import FloatLabel from "primevue/floatlabel";
import Image from "primevue/image";
import ContextMenu from "primevue/contextmenu";
import Tag from "primevue/tag";
import Ripple from "primevue/ripple";
import Toolbar from "primevue/toolbar";
import Card from "primevue/card";

let initOptions = {
  url: "https://login.eetjethuis.nl/",
  realm: "eetjethuis",
  clientId: "app-vue",
  onLoad: "login-required",
};

var keycloak = new Keycloak(initOptions);
export default keycloak;

const app = createApp(App).use(router);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      console.log("Authenticated");
      timeofday();
      app.provide("keycloak", keycloak);
      app.provide("time", time);
      app.provide("auth", auth);
      app.use(PrimeVue, { ripple: true });
      app.directive("ripple", Ripple);
      app.use(ConfirmationService);
      app.use(ToastService);
      app.component("DataTable", DataTable);
      app.component("Column", Column);
      app.component("ColumnGroup", ColumnGroup);
      app.component("Row", Row);
      app.component("ConfirmDialog", ConfirmDialog);
      app.component("Button", Button);
      app.component("TabMenu", TabMenu);
      app.component("TabView", TabView);
      app.component("TabPanel", TabPanel);
      app.component("Toast", Toast);
      app.component("Avatar", Avatar);
      app.component("Menu", Menu);
      app.component("InputSwitch", InputSwitch);
      app.component("DataView", DataView);
      app.component("DataViewLayoutOptions", DataViewLayoutOptions);
      app.component("InputText", InputText);
      app.component("Skeleton", Skeleton);
      app.component("InputMask", InputMask);
      app.component("Calendar", Calendar);
      app.component("Dialog", Dialog);
      app.component("FileUpload", FileUpload);
      app.component("TieredMenu", TieredMenu);
      app.component("InlineMessage", InlineMessage);
      app.component("Divider", Divider);
      app.component("Textarea", Textarea);
      app.component("Panel", Panel);
      app.component("ProgressSpinner", ProgressSpinner);
      app.component("FloatLabel", FloatLabel);
      app.component("moment", moment);
      app.component("Image", Image);
      app.component("ContextMenu", ContextMenu);
      app.component("Tag", Tag);
      app.component("Toolbar", Toolbar);
      app.component("Card", Card);
      app.mount("#app");
    }

    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token refreshed");
          }
          // else {
          //   console.log('Token not refreshed, valid for '
          //     + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          // }
        })
        .catch(() => {
          console.log("Failed to refresh token");
        });
    }, 6000);
  })
  .catch(() => {
    console.log("Authenticated Failed");
  });

var time = "";

function timeofday() {
  var today = new Date();
  var hour = today.getHours();
  if (hour >= 0 && hour < 6) {
    time = "Goedenacht";
    return;
  }
  if (hour >= 6 && hour < 12) {
    time = "Goedemorgen";
    return;
  }
  if (hour >= 12 && hour < 18) {
    time = "Goedemiddag";
    return;
  }
  if (hour >= 18 && hour <= 23) {
    time = "Goedenavond";
    return;
  }
}

// Linkjes om te gebruiken
// Sidebar: https://www.vuescript.com/sidebar-navigation-component-for-vue/
// Check email validation: https://masteringjs.io/tutorials/vue/email-validation

//Image cropper: https://cropper.chengpeiquan.com/quick-start.html
