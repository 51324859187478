<template>
  <div class="parent-div mt-4 mb-4">
    <div class="child1">
      <div class="toolbar shadow-2 surface-card float-left">
        <div class="weekselector justify-content-center align-items-center">
          <Button
            severity="secondary"
            rounded
            @click="changeWeekIndex('prevWeek')"
            title="Vorige"
            id="prevWeek"
            :disabled="isDisabled"
            icon="pi pi-angle-double-left"
          >
          </Button>
          <h1 class="weekselectorcontent user-select-n font-m">
            Week {{ weekNumber }}
          </h1>
          <Button
            severity="secondary"
            rounded
            @click="changeWeekIndex('nextWeek')"
            title="Volgende"
            id="nextWeek"
            :disabled="isDisabled1"
            icon="pi pi-angle-double-right"
          >
          </Button>
        </div>
      </div>
    </div>
    <div class="child2">
      <div class="toolbar-extra-opties shadow-2 surface-card float-right">
        <div class="flex gap-2 justify-content-center">
          <Button
            class="width100"
            label="Gehele week aanmelden"
            severity="success"
            rounded
            @click="
              registerForWeek(locatieOverzicht.meals, weekIndex, locationID)
            "
          />
          <Button
            class="p-button-sm"
            severity="info"
            title="Herladen"
            rounded
            icon="pi pi-refresh"
            @click="refreshData('refresh')"
          />
        </div>
      </div>
    </div>
  </div>
  <section class="card1">
    <div class="grid">
      <template
        v-for="(dates, index) in locatieOverzicht.meals"
        :key="dates.date"
      >
        <div
          class="col-12 md:col-6 lg:col-3"
          v-if="index >= weekIndex * 7 && index <= weekIndex * 7 + 6"
        >
          <DayOverview
            @refresh="refreshData()"
            v-bind:dates="dates"
            v-bind:index="index"
            v-bind:formattedToday="formattedToday"
            v-bind:locationID="locationID"
            v-bind:weekIndex="weekIndex"
            v-bind:locationName="locationName"
            v-bind:locatieOverzicht="locatieOverzicht"
            v-bind:registeredUser="registeredUser"
          />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import DayOverview from "./DayOverview.vue";
import { ref } from "vue";
import { fetchData, getLocatieOverzichtdata } from "@/fetch";
import { serverURL, baseURL } from "@/fetch";
import keycloak from "@/main";

export default {
  name: "PersonOverview",
  components: { DayOverview },
  props: [
    "locationID",
    "locationName",
    "weekIndex",
    "weekNumber",
    "isDisabled",
    "isDisabled1",
    "formattedToday",
  ],
  emits: ["changeWeekIndex"],
  inject: ["keycloak"],
  mounted() {
    this.loadLocationData();
  },
  methods: {
    async loadLocationData() {
      const LocatieData = ref(await getLocatieOverzichtdata(this.locationID));
      this.locatieOverzicht = LocatieData;
      this.registeredUser = fetchData;
    },
    async refreshData(data) {
      const LocatieData = ref(await getLocatieOverzichtdata(this.locationID));
      this.locatieOverzicht = LocatieData;
      this.registerDays = [];
      if (data === "refresh") {
        this.$toast.add({
          severity: "success",
          summary: "Ververst!",
          detail: "De gegevens zijn ververst",
          life: 3000,
        });
      }
    },
    changeWeekIndex(data) {
      this.$emit("changeWeekIndex", data);
    },
    async registerForWeek(dates, weekIndex, locationID) {
      dates.forEach((element) => {
        this.registerDays.push(element.date);
        // this.registerDays.push(
        //   "2024-03-25",
        //   "2024-03-26",
        //   "2024-03-27",
        //   "Friet",
        //   "2024-03-29",
        //   "2024-03-30",
        //   "gratis"
        // );
      });
      this.error = false;
      for (let i = 0; i < 7 && this.error === false; i++) {
        await this.registerForMeal(
          this.registerDays[i + weekIndex * 7],
          locationID
        );
      }
      if (this.error === false) {
        this.$toast.add({
          severity: "success",
          summary: "Geslaagd",
          detail: "U bent succesvol aangemeld!",
          life: 3000,
        });
      }
      this.refreshData();
    },
    async registerForMeal(date, locationID) {
      const seccondURL = locationID;
      await fetch(
        `${serverURL}/${baseURL}/${seccondURL}/command/register-for-meal`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({
            date: date,
            mealType: "DINNER",
          }),
        }
      ).then(
        function (response) {
          if (response.ok !== true) {
            this.error = true;
            this.$toast.add({
              severity: "error",
              summary: "Er is een fout opgetreden.",
              detail: "Probeer het opnieuw!",
              life: 3000,
            });
          }
        }.bind(this)
      );
    },
  },
  data() {
    return {
      visible: false,
      dateClicked: {},
      locatieOverzicht: {},
      registeredUser: {},
      registerDays: [],
      error: false,
    };
  },
};
</script>

<style scoped>
.mh-130 {
  position: relative;
}

.divider-class {
  margin: 0 !important;
  bottom: 1rem;
}

.parent-div {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  vertical-align: middle;
}

@media (max-width: 644px) {
  .parent-div {
    grid-template-columns: 1.4fr 2fr;
  }
}

@media (max-width: 555px) {
  .child1 {
    grid-area: 1 / 1 / 2 / 2 !important;
  }
  .child2 {
    grid-area: 2 / 1 / 3 / 2 !important;
  }
  .parent-div {
    grid-template-columns: 2fr;
  }
  .float-right {
    float: none !important;
  }

  .float-left {
    float: none !important;
  }
}

.child1 {
  grid-area: 1 / 1 / 2 / 2;
}
.child2 {
  grid-area: 1 / 2 / 2 / 3;
}

.toolbar {
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-radius: 6px;
}
.toolbar {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 3rem;
  padding: 0.5rem;
}

.toolbar-extra-opties {
  display: grid;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  padding: 1.25rem;
  border-radius: 6px;
}
.toolbar-extra-opties {
  border-radius: 3rem;
  padding: 0.5rem;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}
</style>
