<template>
  <div class="Home">
    <div><HomeOverview /></div>
  </div>
</template>

<script>
import HomeOverview from "@/components/App/LocationOverview/HomeOverview.vue";

export default {
  name: "LocationOverview",
  components: {
    HomeOverview,
  },
};
</script>
