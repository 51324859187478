<template>
  <Dialog
    v-model:visible="this.visible"
    modal
    header="Overzicht"
    :style="{ width: '50vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <template
      v-for="(dates, index) in locatieOverzicht.meals"
      :key="dates.date"
    >
      <Divider v-if="dates.date === dateClicked" />
      <div
        class="font-m font-bold"
        style="margin-top: 20px"
        v-if="dates.date === dateClicked"
      >
        Loactienaam:
        <div>
          <i class="fa-solid fa-house fa-xs"></i>
          {{ locationName }}
        </div>
        <p style="margin-top: 20px" class="font-m font-bold">Datum:</p>
        <div>
          <i class="fa-regular fa-calendar fa-xs"></i>
          {{ weekday[index].day }} - {{ dateTime(dates.date) }}
        </div>
        <p style="margin-top: 20px" class="font-m font-bold">
          Totaal aantal aanmeldingen:
        </p>
        <p>{{ dates.registrations.length }}</p>
        <p style="margin-top: 20px" class="font-m font-bold">Wat eten we:</p>
        <div class="text-900 font-m">
          <span
            class="font-s text-color-gray"
            v-if="dates.memo === '' || dates.memo === null"
          >
            Er is nog geen maaltijd ingevoerd</span
          >
          {{ dates.memo }}
        </div>
        <p style="margin-top: 20px" class="font-m font-bold">Aanmeldingen:</p>
        <div
          class="text-900 font-m capitalize"
          v-for="users in dates.registrations"
        >
          <span style="text-align: center">
            <div style="float: left; clear: left; text-align: left">
              <i
                class="fa-solid fa-user fa-xs"
                :class="{ colorBlue: users.id == registeredUser.id }"
              ></i>
              {{ users.name }}
              <span v-if="users.id == registeredUser.id">(jij)</span>
              <span
                style="margin-left: 5px"
                class="text-color-gray not-capitalize"
                v-if="users.memo"
              >
                -
                <i class="fa-regular fa-comment fa-sm"></i>
                {{ users.memo }}</span
              >
            </div>
          </span>
        </div>
      </div>
      <div
        v-if="!dates.registrations[0] && dates.date === dateClicked"
        class="text-color-gray font-s"
      >
        Er zijn nog geen aanmeldingen voor deze dag!
      </div>
    </template>
  </Dialog>
  <div class="surface-card shadow-2 p-3 border-round mh-130 user-select-n">
    <div class="flex justify-content-between mb-3 mb-2">
      <div>
        <PersonList
          v-bind:dates="dates"
          v-bind:index="index"
          v-bind:weekday="weekday"
          v-bind:formattedToday="formattedToday"
        />
      </div>
      <div>
        <div class="flex">
          <div>
            <div v-for="users in dates.registrations">
              <Button
                icon="pi pi-comment"
                text
                severity="secondary"
                rounded
                style="width: 2rem; height: 2rem"
                @click="show[index] = !show[index]"
                v-if="registeredUser.id == users.id"
                title="Opmerking toevoegen"
              />
            </div>
          </div>
          <div>
            <Button
              icon="pi pi-times"
              severity="danger"
              text
              rounded
              style="width: 2rem; height: 2rem"
              title="Afmelden"
              @click="signOut(dates.date, index)"
              v-if="isUserRegistered(dates, registeredUser)"
            />
            <Button
              icon="pi pi-plus"
              text
              rounded
              severity="success"
              style="width: 2rem; height: 2rem"
              @click="registerForMeal(dates.date)"
              title="Aanmelden"
              v-if="!isUserRegistered(dates, registeredUser)"
            />
            <div>
              <Button
                icon="pi pi-window-maximize"
                text
                rounded
                style="width: 2rem; height: 2rem"
                @click="(visible = true), (this.dateClicked = dates.date)"
                title="Uitklappen"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <NoteField
        v-if="show[index] == true"
        v-bind:dates="dates"
        v-bind:index="index"
        v-bind:locationID="locationID"
      />
    </div>
    <div
      :class="{
        height127: showMenu[index] || show[index],
        height187: showMenu[index] && show[index],
      }"
      style="height: 67px"
    >
      <div class="text-block1">
        <Divider class="divider-class" />
        <div class="flex justify-content-between">
          <div :class="{ 'mb-3': showMenu[index] }">
            <div>
              <span class="font-m">Wat eten we:</span>
            </div>

            <div
              class="text-color-gray font-m"
              style="display: flex; align-items: center"
            >
              <i class="pi pi-angle-right"></i>
              <span
                class="font-s text-color-gray"
                v-if="dates.memo === '' || dates.memo === null"
              >
                Er is nog geen maaltijd ingevoerd</span
              >
              {{ dates.memo }}
            </div>
          </div>
          <div>
            <Button
              icon="pi pi-pencil"
              text
              severity="secondary"
              rounded
              style="width: 2rem; height: 2rem"
              @click="showMenu[index] = !showMenu[index]"
              title="Menu toevoegen"
            />
          </div>
        </div>
        <div>
          <MenuField
            v-if="showMenu[index] === true"
            v-bind:dates="dates"
            v-bind:index="index"
            v-bind:locationID="locationID"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import keycloak from "@/main";
import NoteField from "./NoteField.vue";
import MenuField from "./MenuField.vue";
import PersonList from "./PersonList.vue";
import moment from "moment";
import { ref } from "vue";
import { serverURL, baseURL } from "@/fetch";

const visible = ref(false);
const dateClicked = ref();

export default {
  name: "DayOverview",
  components: { NoteField, PersonList, MenuField },
  emits: ["refresh"],
  props: [
    "locationID",
    "weekIndex",
    "formattedToday",
    "locationName",
    "dates",
    "index",
    "locatieOverzicht",
    "registeredUser",
  ],
  inject: ["keycloak"],
  methods: {
    async refreshData1() {
      this.$emit("refresh");
    },
    signOut(date, index) {
      this.$confirm.require({
        group: "headless",
        message: "Weet u zeker dat u uzelf wilt afmelden voor deze datum?",
        header: "Afmelden?",
        // image: "/img/despicable-me-agnes.gif",
        accept: () => {
          let self = this;

          const seccondURL = self.locationID;
          fetch(
            `${serverURL}/${baseURL}/${seccondURL}/command/unregister-for-meal`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + keycloak.idToken,
              },
              body: JSON.stringify({
                date: date,
                mealType: "DINNER",
              }),
            }
          )
            .then(() => (this.$emit("refresh"), (this.show[index] = null)))
            .then(() =>
              this.$toast.add({
                severity: "success",
                summary: "Afgemeld!",
                detail: "U bent afgemeld voor deze datum!",
                life: 3000,
              })
            );
        },
      });
    },
    async registerForMeal(date) {
      let self = this;

      const seccondURL = self.locationID;
      fetch(`${serverURL}/${baseURL}/${seccondURL}/command/register-for-meal`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + keycloak.idToken,
        },
        body: JSON.stringify({
          date: date,
          mealType: "DINNER",
        }),
      })
        .then(() => this.$emit("refresh"))
        .then(() =>
          this.$toast.add({
            severity: "success",
            summary: "Toegevoegd!",
            detail: "U bent succesvol aangemeld!",
            life: 3000,
          })
        );
    },
    isUserRegistered(dates, registeredUser) {
      for (let i = 0; i < dates.registrations.length; i++) {
        if (dates.registrations[i].id == registeredUser.id) {
          return true;
        }
      }
      return false;
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
  data() {
    return {
      visible: false,
      dateClicked: {},
      weekday: [
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
        {
          day: "Maandag",
        },
        {
          day: "Dinsdag",
        },
        {
          day: "Woensdag",
        },
        {
          day: "Donderdag",
        },
        {
          day: "Vrijdag",
        },
        {
          day: "Zaterdag",
        },
        {
          day: "Zondag",
        },
      ],
      show: [null],
      showMenu: [null],
    };
  },
};
</script>

<style scoped>
.mh-130 {
  position: relative;
}

.divider-class {
  margin: 0 !important;
  bottom: 1rem;
}
</style>

<!--           
  v-if="weekIndex == 1 && index >= 7 && index <= 13" 
  v-if="weekIndex == 2 && index >= 14 && index <= 20"
-->
