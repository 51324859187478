<template>
  <form
    class="height44"
    @submit.prevent="addMenu(dates.date, opmerkingen[index])"
  >
    <span class="p-float-label button-style" style="width: 100%">
      <InputText
        v-model="opmerkingen[index]"
        class="text-notes font-s"
        rows="1"
      />
      <label>Maaltijd</label>
      <Button v-if="!opmerkingen[index]" disabled class="memo-button"
        ><i class="fa-regular fa-floppy-disk fa-lg"></i
      ></Button>
      <Button
        v-else
        @click="addMenu(dates.date, opmerkingen[index])"
        class="memo-button"
        ><i class="fa-regular fa-floppy-disk fa-lg"></i
      ></Button>
      <Button
        v-if="!dates.memo === null || !dates.memo == ''"
        @click="deleteMenu(dates.date)"
        class="memo-button"
        title="Opmerking verwijderen"
        ><i class="fa-regular fa-trash-can"></i
      ></Button>
    </span>
  </form>
</template>

<script>
import { fetchData, baseURL, serverURL } from "@/fetch";

import keycloak from "@/main";
export default {
  name: "MenuField",
  props: ["dates", "index", "locationID"],
  mounted() {
    this.registeredUser = fetchData;
  },
  methods: {
    refreshData() {
      this.$parent.refreshData1();
    },
    async addMenu(date, opmerkingen) {
      if (opmerkingen) {
        let self = this;

        const seccondURL = self.locationID;
        fetch(
          `${serverURL}/${baseURL}/${seccondURL}/command/change-memo-for-meal
`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + keycloak.idToken,
            },
            body: JSON.stringify({
              date: date,
              mealType: "DINNER",
              memo: opmerkingen,
            }),
          }
        ).then(
          () => this.refreshData(),
          this.$toast.add({
            severity: "success",
            summary: "opgeslagen!",
            detail: "De door jou ingevulde opmerking is opgeslagen!",
            life: 3000,
          }),
          (this.opmerkingen = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ])
        );
      }
    },
    deleteMenu(date) {
      let self = this;

      const seccondURL = self.locationID;
      fetch(
        `${serverURL}/${baseURL}/${seccondURL}/command/change-memo-for-meal
`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + keycloak.idToken,
          },
          body: JSON.stringify({
            date: date,
            mealType: "DINNER",
            memo: "",
          }),
        }
      )
        .then(() => this.refreshData(), (this.opmerkingen = []))
        .then(() =>
          this.$toast.add({
            severity: "success",
            summary: "opgeslagen!",
            detail: "Je opmerking is verwijderd!",
            life: 3000,
          })
        );
    },
  },
  data() {
    return {
      registeredUser: {},
      opmerkingen: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    };
  },
};
</script>
