<template>
  <div class="dashboard">
    <div class="Dashboardtitle">
      <h4 class="user-select-n font-header">Welkom bij Eetjethuis.nl</h4>
    </div>
    <div style="margin: 1rem">
      <Dashboard />
    </div>
  </div>
</template>

<script>
import Dashboard from "./Dashboard.vue";
export default {
  name: "HomeDashboard",
  inject: ["keycloak"],
  components: {
    Dashboard,
  },
};
</script>
