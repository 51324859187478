<template>
  <div class="Home">
    <div class="houseselection">
      <HouseSelection />
    </div>
  </div>
</template>

<script>
import HouseSelection from "@/components/App/Aanmelden/HouseSelection.vue";

export default {
  name: "Aanmelden",
  components: {
    HouseSelection,
  },
};
</script>
